import { Component,OnInit,ViewChild,NgZone } from '@angular/core';
import { Platform,IonRouterOutlet } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router,ActivatedRoute } from '@angular/router';
import { AuthService } from './services/auth.service';
import { MessagingService } from './services/messaging.service';
import { TranslateConfigService } from './services/translate-config.service';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { FCM } from '@ionic-native/fcm/ngx';
import { ToastController } from '@ionic/angular';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
	providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
})
export class AppComponent implements OnInit{
		@ViewChild(IonRouterOutlet,{static:true}) routerOutlet: IonRouterOutlet; 
	 projectval:any;
	userId:any;
	locationval:any;
	tmp:boolean;
	tmp2:boolean;
  constructor(
	private ngZone: NgZone,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private router: Router,
	private androidPermissions: AndroidPermissions,
	 private route:ActivatedRoute,
	 private fcm: FCM,
	 private translateService:TranslateConfigService,
	 private messagingService:MessagingService,
	 private toastController: ToastController,
	 private location: Location
  ) {
	
   // this.initializeApp();
  }

	ngOnInit(){
		this.initializeApp();
		this.Permission();
	}
	
  initializeApp() {
    this.platform.ready().then(() => {
		this.translateService.setInitialLanguage();
		 if (this.platform.is('android')) {
           console.log('android');
			 this.authService.setPlatform("android");
      } else if (this.platform.is('ios')) {
           console.log('ios');
		  	 this.authService.setPlatform("ios");
      } else {
           //fallback to browser APIs or
           console.log('The platform is not supported');
		  	 this.authService.setPlatform("browser");
             }
      this.statusBar.styleDefault();
      this.splashScreen.hide();
		var self=this;
		this.platform.backButton.subscribeWithPriority(0, () => {
			if (this.routerOutlet && this.routerOutlet.canGoBack()) {
			  this.routerOutlet.pop();
			  navigator['app'].exitApp();
			  console.log("normal back");
			} 
			else {  
				navigator['app'].exitApp();
				console.log("normal back exit");
			}
		  });
		 this.fcm.onNotification().subscribe(data => {
          if (data.wasTapped) {
            console.log("Received in background");
			  var location=data.locationparam;
			  var projectid=data.projectidparam;
			  this.ngZone.run(()=>{
				  			  if(location=="project"){
				  	self.router.navigate(['/project'],{queryParams:{notify:'project'}});
			  }
			  else if(location=="uctab"){
				 self.router.navigate(['/service'],{queryParams:{url:'ucsendnotification',proId:projectid}});
			  }
			  else if(location=="banktab"){
				  self.router.navigate(['/service'],{queryParams:{url:'banksendnotification',proId:projectid}});
			  }
			  })			  
          } else {
            console.log("Received in foreground");
			  this.presentToast();
          };
        });
      this.authService.authenticationState.subscribe(state => {
		  //console.log("state",state);
		  var param=this.location.path();
		  //console.log("some param new",param);
		  if(param!=null || param!=undefined){
			  var check=param.includes("pid");
			  if(check){
				  var data=param.split("=");
				 // console.log("data",data);
				  this.locationval=data[2];
				  this.projectval=data[1].substring(0,data[1].indexOf("&"));
				  this.tmp=true;
			  }
		  }

		  if (state) {
			if(this.tmp || this.tmp2){
				this.router.navigate(['/menu'],{queryParams:{projectId:this.projectval,location:this.locationval}});
			}
			else{
				this.router.navigate(['/menu']);
			}         
			
        } else {
			console.log("tmp2",this.tmp);
			if(this.tmp){
				this.tmp2=true;
			}
			        this.router.navigate(['login']);  
        }
      });
      
    });
  }
	 async presentToast() {
    const toast = await this.toastController.create({
      message: 'You Have A New Message Please Check Your Notifications .',
      duration: 4000
    });
    toast.present();
  }
	Permission(){
		this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE).then(
  result => console.log('Has permission?',result.hasPermission),
  err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE)
);
		this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
  result => console.log('Has permission?',result.hasPermission),
  err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
);

this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE, this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE]);
	}
}
