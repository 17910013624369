import { AuthGuardService } from './services/auth-guard.service';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
const routes: Routes = [	 
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  // { path: 'signup', loadChildren: './pages/signup/signup.module#SignupPageModule' },
  { path: 'menu', loadChildren: './pages/menu/menu.module#MenuPageModule' },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'password-update',
    loadChildren: () => import('./pages/password-update/password-update.module').then( m => m.PasswordUpdatePageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/manage-user/user.module').then( m => m.ManageUserModule)
  },
  {
    path: 'project',
    loadChildren: () => import('./pages/manage-project/project.module').then( m => m.ManageProjectModule)
  },
  {
    path: 'question',
    loadChildren: () => import('./pages/manage-question/question.module').then( m => m.ManageQuestionModule)
  },
  {
    path: 'service',
    loadChildren: () => import('./pages/manage-service/manage-service.module').then( m => m.ManageServicePageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
