import { Platform, AlertController, LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
const TOKEN_KEY = 'access_token';
 
@Injectable({
  providedIn: 'root'
})
export class AuthService {
 platform:any;
  url = environment.url;
  user = null;
  authenticationState = new BehaviorSubject(false);
  currentUser:Object;

  constructor(private http: HttpClient, private helper: JwtHelperService, private storage: Storage,
    private plt: Platform, private alertController: AlertController, public loadingController: LoadingController) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }
 
  checkToken() {
    this.storage.get(TOKEN_KEY).then(token => {
      if (token) {
        let decoded = this.helper.decodeToken(token);
        let isExpired = this.helper.isTokenExpired(token);
 
        if (!isExpired) {
          this.user = decoded;
          this.authenticationState.next(true);
        } else {
          this.storage.remove(TOKEN_KEY);
        }
      }
    });
  }
 
  register(credentials) {
    console.log(credentials);    
    return this.http.post(`${this.url}/api/register`, credentials).pipe(
      catchError(e => {
        console.log(e);        
        this.showAlert('Error', e.error.msg, false);
        throw new Error(e);
      })
    );
  }
 
  login(credentials) {
    return this.http.post(`${this.url}/api/login`, credentials)
      .pipe(
        tap(res => {
          this.storage.set(TOKEN_KEY, res['token']);
          this.user = this.helper.decodeToken(res['token']);
          this.authenticationState.next(true);
        }),
        catchError(e => {
          this.showAlert('Error', e.error.msg, false);
          throw new Error(e);
        })
      );
  }

  updatePassword(credentials) {
    return this.http.post(`${this.url}/api/changePassword`, credentials).pipe(
      catchError(e => {
        console.log(e);        
        this.showAlert('Error', e.error.msg, false);
        throw new Error(e);
      })
    );
  }

 
  logout() {
    this.storage.remove(TOKEN_KEY).then(() => {
      this.authenticationState.next(false);
    });
  }
 
  getSpecialData() {
    return this.http.get(`${this.url}/api/special`).pipe(
      catchError(e => {
        let status = e.status;
        if (status === 401) {
          this.showAlert('Error', 'You are not authorized for this!', false);
          this.logout();
        }
        throw new Error(e);
      })
    )
  }

  getUserProfileData() {
    return this.http.get(`${this.url}/api/profileData`).pipe(
      catchError(e => {
        let status = e.status;
        if (status === 401) {
          this.showAlert('Error', 'You are not authorized for this!', false);
          this.logout();
        }
        throw new Error(e);
      })
    )
  }
  
  
  saveProfile(profile) {
    return this.http.post(`${this.url}/api/profile`, profile).pipe(
      catchError(e => {
        console.log(e);        
        this.showAlert('Error', e.error.msg, false);
        throw new Error(e);
      })
    );
  }

  getProfile() {
    return this.http.get(`${this.url}/api/profile`).pipe(
      catchError(e => {
        console.log(e);        
        //this.showAlert('Error', e.error.msg, false);
        throw new Error(e);
      })
    );
  }
 
  isAuthenticated() {
    return this.authenticationState.value;
  }
 
  async showAlert(ttl, msg, conf) {
    let btns, choice;

    if (conf) {
      btns = [
        {
          text: 'No',
          role: false,
          handler: () => {
            alert.dismiss(false);
            return false;
          }
        },
        {
          text: 'Yes',
          role: true,
          handler: () => {
            alert.dismiss(true);
            return true;
          }
        }
      ];
    } else {
      btns = [
        {
          text: 'OK',
          role: false,
          handler: () => {
            alert.dismiss(false);
            return false;
          }
        }
      ]
    }
    let alert = await this.alertController.create({
      header: ttl,
      message: msg,
      buttons: btns
    });
    await alert.present();
    await alert.onDidDismiss().then((data) => {
      choice = data
    })
    return choice
  }

  showLoader(msg) {
    const loading = this.loadingController.create({
      message: 'Processing'
    });
    if(msg=='present') {
      loading.then(loading => loading.present());
    } else if(msg=='dismiss') {
      loading.then(loading => loading.dismiss());
    }
  }
	getPlatform(){
		return this.platform;
	}
	
	setPlatform(platform){
		this.platform=platform;
	}
	
}