import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {

  constructor(
    private translate: TranslateService,
	 private storage: Storage
  ) { }

	
	setInitialLanguage(){
		let language = this.translate.getBrowserLang();
		console.log("language",language);
    this.translate.setDefaultLang(language);
		 this.storage.get('language').then((val) => {
			 if(val){
				 console.log("val",val);
				this.setLanguage(val);	 
			 }
  		});
	}
  getDefaultLanguage(){
	  var language;
	  this.storage.get('language').then((val) => {
		   if(val){
				language=val;	 
			    console.log("getDefaultLanguage",language);
   				 return language;
			 }
		  else{
			  language=this.translate.getBrowserLang();
			   console.log("getDefaultLanguage",language);
    			return language;
		  }
  		});
  
  }

  setLanguage(setLang) {
	  console.log("setLang",setLang);
    this.translate.use(setLang);
	  this.storage.set('language',setLang);
  }

}
